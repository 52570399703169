<template>
  <b-form-group
    label="Fatura Tarihi (Araç Alış)"
    label-for="purchase_invoice"
  >
    <cleave
      id="purchase_invoice"
      v-model="car.purchase_invoice"
      class="form-control"
      :raw="false"
      :options="options.date"
      placeholder="Fatura Tarihi (Araç Alış)"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PurchaseInvoice',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped>
</style>
