<template>
  <b-form-group
    label="Ruhsat No"
    label-for="license_number"
  >
    <b-form-input
      id="license_number"
      v-model="car.license_number"
      placeholder="Ruhsat No"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'LicenseNumber',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>
<style scoped>
</style>
