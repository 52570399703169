<template>
  <b-form-group
    label="Fatura No (Araç Satış)"
    label-for="sales_invoice_no"
  >
    <b-form-input
      id="sales_invoice_no"
      v-model="car.sales_invoice_no"
      placeholder="Fatura No (Araç Satış)"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'SalesInvoiceNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>
<style scoped>
</style>
