import { render, staticRenderFns } from "./License.vue?vue&type=template&id=e83a94c0&scoped=true&"
import script from "./License.vue?vue&type=script&lang=js&"
export * from "./License.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e83a94c0",
  null
  
)

export default component.exports