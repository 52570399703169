<template>
  <b-card>
    <b-row>
      <b-col>
        <b-card-title>Araç Bilgileri</b-card-title>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="car.detailForm"
          name="check-button"
          switch
          inline
        >
          Detaylı Form
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <brands />
      </b-col>
      <b-col>
        <models />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hardware />
      </b-col>
      <b-col>
        <model-year />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <colors />
      </b-col>
      <b-col><fuels /></b-col>
      <b-col><gears /></b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <license-plate />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Brands from '@/views/Cars/forms/Brands'
// eslint-disable-next-line import/extensions
import Models from '@/views/Cars/forms/Models'
// eslint-disable-next-line import/extensions
import Hardware from '@/views/Cars/forms/Hardware'
// eslint-disable-next-line import/extensions
import ModelYear from '@/views/Cars/forms/Model_year'
// eslint-disable-next-line import/extensions
import Colors from '@/views/Cars/forms/Colors'
// eslint-disable-next-line import/extensions
import Fuels from '@/views/Cars/forms/Fuels'
// eslint-disable-next-line import/extensions
import Gears from '@/views/Cars/forms/Gears'
// eslint-disable-next-line import/extensions
import LicensePlate from '@/views/Cars/forms/License_plate'

export default {
  name: 'Public',
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    Brands,
    Models,
    Hardware,
    ModelYear,
    Colors,
    Fuels,
    Gears,
    LicensePlate,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped>

</style>
