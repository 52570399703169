<template>
  <b-card title="Ruhsat Bilgileri">
    <b-row>
      <b-col>
        <license-number />
      </b-col>
      <b-col>
        <chasis />
      </b-col>
      <b-col>
        <engine />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import LicenseNumber from '@/views/Cars/forms/License_number'
// eslint-disable-next-line import/extensions
import Chasis from '@/views/Cars/forms/Chasis'
// eslint-disable-next-line import/extensions
import Engine from '@/views/Cars/forms/Engine'

export default {
  name: 'License',
  components: {
    BCard,
    BRow,
    BCol,
    LicenseNumber,
    Chasis,
    Engine,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped>

</style>
