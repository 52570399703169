<template>
  <b-card title="Diğer Bilgiler">
    <b-row>
      <b-col>
        <purchase-invoice-no />
      </b-col>
      <b-col>
        <purchase-invoice />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <sales-invoice-no />
      </b-col>
      <b-col>
        <sales-invoice />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <kasko />
      </b-col>
      <b-col>
        <trafik />
      </b-col>
      <b-col>
        <muayene />
      </b-col>
      <b-col>
        <egzoz />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <warranty-start />
      </b-col>
      <b-col>
        <warranty-end />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import PurchaseInvoice from '@/views/Cars/forms/Purchase_invoice'
// eslint-disable-next-line import/extensions
import PurchaseInvoiceNo from '@/views/Cars/forms/Purchase_invoice_no'
// eslint-disable-next-line import/extensions
import SalesInvoice from '@/views/Cars/forms/Sales_invoice'
// eslint-disable-next-line import/extensions
import SalesInvoiceNo from '@/views/Cars/forms/Sales_invoice_no'
// eslint-disable-next-line import/extensions
import Kasko from '@/views/Cars/forms/Kasko'
// eslint-disable-next-line import/extensions
import Trafik from '@/views/Cars/forms/Trafik'
// eslint-disable-next-line import/extensions
import Muayene from '@/views/Cars/forms/Muayene'
// eslint-disable-next-line import/extensions
import Egzoz from '@/views/Cars/forms/Egzoz'
// eslint-disable-next-line import/extensions
import WarrantyStart from '@/views/Cars/forms/Warranty_start'
// eslint-disable-next-line import/extensions
import WarrantyEnd from '@/views/Cars/forms/Warranty_end'

export default {
  name: 'Other',
  components: {
    BCard,
    BRow,
    BCol,
    PurchaseInvoice,
    PurchaseInvoiceNo,
    SalesInvoice,
    SalesInvoiceNo,
    Kasko,
    Trafik,
    Muayene,
    Egzoz,
    WarrantyStart,
    WarrantyEnd,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped>

</style>
