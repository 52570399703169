<template>
  <b-form-group
    label="Fatura No (Araç Alış)"
    label-for="purchase_invoice_no"
  >
    <b-form-input
      id="purchase_invoice_no"
      v-model="car.purchase_invoice_no"
      placeholder="Fatura No (Araç Alış)"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'PurchaseInvoiceNo',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>
<style scoped>
</style>
